<template>
<div>
  <canvas ref="lineChart" style="height: 600px"/>
</div>
</template>

<script>
import { Chart, registerables } from 'chart.js';

export default {
  name: 'LineChart',
  props: ['chart_labels', 'chart_values', 'y_label'],
  computed: {
  },
  data: () => ({
    chart: null,
  }),
  created() {
    this.$debug("created", this.pond)
    Chart.register(...registerables);
  },
  watch: {
    chart_values: {
      handler: function () {
        this.$debug("watch data", this.chart_values, this.chart, this.chart_values.labels, )
        if(this.chart_values.length) this.makeChart()
      },
      immediate: true
    }
  },
  mounted () {
    this.$debug('mounted')
    // this.makeChart()
  },
  methods: {
    updateChart() {
      this.$debug( "updateChart", this.$refs, this.$refs.lineChart, "this.chart", this.chart, "this.chart_values", this.chart_values )
      
      this.chart.data.labels.push(this.chart_values.labels);
      this.chart.data.datasets.forEach((dataset) => {
          dataset.data.push(this.chart_values.values);
      });
      this.chart.update();
    },
    makeChart() {
      this.$debug( "makeChart", this.$refs, this.$refs.lineChart, "this.chart_values", this.chart_labels, this.chart_values )
      if( !this.$refs.lineChart ) return;

      let ctx = this.$refs.lineChart.getContext("2d");

      // do not show 0 in the chart
      const new_chart_values = this.chart_values.map( (o, i) => {
        if( i && o==0 ) return NaN;
        else return o;
      })     
      this.$debug( "new_chart_values", new_chart_values )

      this.chart = new Chart(ctx, {
          type: "line", 
          data: {
              labels: this.chart_labels,
              // labels: ['a', 'b'],
              datasets: [
                  {
                      label: "",
                      data: new_chart_values,
                      backgroundColor: "rgb(255, 99, 132, 0.5)",
                      borderColor: "rgb(255, 99, 132)",
                      fill: false,
                      // borderWidth: 1,
                      barPercentage: 0.1, 
                      borderRadius: 5,
                      pointStyle: 'circle',
                      pointRadius: 10,
                      pointHoverRadius: 15
                  }
              ]
          },
          options: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                  x: {
                      title: {
                        text: 'Result Date',
                        display: true,
                      },
                      grid: {
                          drawTicks: false
                      },
                      ticks: {
                          padding: 10
                      }
                  },
                  y: {
                      title: {
                        text: this.y_label,
                        display: true
                      },
                      grid: {
                          drawTicks: false
                      },
                      ticks: {
                          padding: 10
                      }
                  }
              },
              plugins: {
                  legend: {
                      display: false,
                  },
                  tooltip: {
                      // backgroundColor: '#fff',
                      // borderColor: '#D0D0D0',
                      // borderWidth: 1,
                      // cornerRadius: 2,
                      // titleColor: '#333333',
                      // bodyColor: '#333333',
                      // callbacks: {
                      //     labelColor: function(context) {
                      //         return {
                      //             // backgroundColor: context.dataset.pointHoverBorderColor
                      //             backgroundColor: context.dataset ? context.dataset.pointHoverBorderColor : 'red'
                      //         }
                      //     },
                      // },
                  }
              },
          }
      })
      this.chart.update();
      this.$debug( "this.chart", this.chart )

    }
  }
}
</script>
<style scoped>

</style>