<template>
<div>
    <title-pond/>
    <v-row style="flex;  align-items: center" class="pt-5">
      <v-col :cols="isMobile ? 12 : 4.5" class="mt-1 mb-0 pb-0">
        <h2 class="font-weight-bold mt-2 pb-3">
            Biomass Report
          </h2>
        <v-simple-table>
          <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left custom-header">
                    Result Date
                </th>
                <th class="text-center custom-header">
                    Sample Weight (g)
                </th>
                <th class="text-center custom-header">
                    {{ showSubtitle }}
                </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in table_data"
                :key="item.woc"
                >
                <td class="d-flex align-center">
                  <!-- <div class="week">W{{ item.woc }}</div> -->
                  <div class="woc-date">
                    {{getWeekDate(item.result_date)}}
                  </div>
                </td>
                <td class="text-center">{{ item.weight }}</td>
                <td class="text-center">{{ item.biomass | formatComma }}</td>
                </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col :cols="isMobile ? 12 : 7" class="mt-1 mb-0 pb-0">
        <v-row style="flex" justify="end" class="mt-1 pb-10">
          <router-link to="/farmersamples">
            <v-btn color="primary" class="ml-2">
              ⬅ Pond Result
            </v-btn>
          </router-link>
          <v-btn color="primary" class="ml-2" @click="generateReport()">
            PDF Export
          </v-btn>
        </v-row>

        <line-chart :chart_labels="chart_labels" :chart_values="chart_values" :y_label="getYLabel">
        </line-chart>
      </v-col>
    </v-row>

    <VueHtml2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :pdf-content-width="1200"
        :paginate-elements-by-height="1400"
        :filename="getPdfName()"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
          <div style="padding: 30px;">
          <v-row>
            <v-col cols="9" class="mt-1 mb-0 pb-0 pl-3">
                <h2 class="font-weight-bold mt-2">
                  Biomass Report : {{pond.farm_name}}, Pond {{pond.pond_id}}, {{this.$options.filters.formatDate(pond.date)}}
                </h2>
            </v-col>
            <v-col cols="3" class="text-right">
              {{currentdate}}
            </v-col>
          </v-row>
          <v-row>
              <v-col :cols="6" class="mt-1 mb-0 pb-0 pl-3">
                  <v-simple-table>
                      <template v-slot:default>
                      <thead>
                          <tr>
                          <th class="text-left">
                              Result Date
                          </th>
                          <th class="text-left">
                              Sample Weight (g)
                          </th>
                          <th class="text-left">
                              {{ showSubtitle }}
                          </th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr
                            v-for="item in table_data"
                            :key="item.woc"
                          >
                          <td class="text-center">
                              {{getWeekDate(item.result_date)}}
                          </td>
                          <td class="text-right pr-4">{{ item.weight }}</td>
                          <td class="text-right pr-4">{{ item.biomass | formatComma }}</td>
                          </tr>
                      </tbody>
                      </template>
                  </v-simple-table>
              </v-col>
              <v-col :cols="6" class="mt-1 mb-0 pb-0">
                  <line-chart :chart_labels="chart_labels" :chart_values="chart_values" :y_label="getYLabel">
                  </line-chart>
              </v-col>
          </v-row>
          </div>
        </section>
    </VueHtml2pdf>
</div>
</template>

<script>
import { mapState } from "vuex";

import tokenAxios from "@/components/tokenAxios";

import LineChart from '@/components/LineChart.vue';
import TitlePond from "@/components/TitlePond";
import moment from 'moment';
import VueHtml2pdf from 'vue-html2pdf';
import { validationMixin } from "vuelidate";
export default {
  name: 'FarmerReport',
  components: {
    LineChart,
    VueHtml2pdf,
    TitlePond
  },
  computed: {
    ...mapState(["user_info", "pond", "isMobile"]), // get user_info and pond from store
    isCount() {
      if(this.$route.query.type == 'biomass') return false;
      else return true;
    },

    showSubtitle() {
      if(this.$route.query.type == 'biomass') return 'Biomass (lbs)';
      else if(this.$route.query.type == 'biomasskg') return 'Biomass (kg)';
      else return 'Count';
    },

    currentdate() {
      return moment().format('DD/MM/YYYY (ddd)')
    },

    getYLabel() {
      if (this.$route.query.type == 'biomasskg') return 'Total Biomass (kg)';
      else return 'Total Biomass (lbs)';
    },
    
  },
  mixins: [validationMixin],
  data: () => ({
    API_SERVER: process.env.VUE_APP_API_SERVER,
    table_data: [],
    chart_labels: [],
    chart_values: [],
    // y_label: getYLabel(),
  }),
  created() {
    this.$debug("created", this.pond)
    if( !this.pond ) this.$router.push('/ponds')
    this.listSamples();
  },
  mounted () {
  },
  methods: {
    // generate report
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
      return false
    },
    
    // get pdf name
    getPdfName() {
      if(this.pond) return `${this.pond.farm_name}-${this.pond.pond_id}-${moment(this.pond.date).format("DD.MM.YYYY")}`
      else return "result";
    },
    
    getWeekDate(d) {
      return moment(d).format("DD/MM/YYYY (ddd)");
    },
    // Get result date short format
    getWeekDateShort(d) {
      return moment(d).format("DD/MM");
    },

    // Get week date from WOC
    getWeekDateFromWOC(week) {
      this.$debug("getWeekDate...", week)
      return moment(this.pond.date).add(week*7, 'days').format("DD/MM/YYYY (ddd)");
    },

    // Error Handling
    fieldErrors(field) {
      this.$debug('fieldErrors', field, this.$v, this.$v[field] )
      if (!this.$v[field].$dirty || !this.$v[field].$error) return [];
      else if (!this.$v[field].decimal) return ["Number is required."];
      else if (!this.$v[field].required) return ["This field is required."];
    },

    // List Samples
    async listSamples() {
      try {
          this.$debug( "API_SERVER", this.API_SERVER, "this.pond", this.pond )
          this.$store.commit("loadingStatus", 1);
          const response = await tokenAxios(this.user_info.access_token).get(
            this.API_SERVER + `/farm/results/?format=json&pond=${this.pond.id}`
          );
          this.$debug("get weekly reports", response);
          if (response.status  == 200 && response.data.results.length) {
            this.table_data = response.data.results.map(o => {
              try {
                if(this.$route.query.type == 'biomass') {
                  o.biomass =  Math.round( (o.count*o.weight)/453.5 )
                } else {
                  o.biomass =  Math.round( (o.count*o.weight)/1000 )
                }
              } catch(e) {
                return
              }
              return o
            });

            // Chart Values
            this.table_data.map(o => {
                // this.chart_labels.push(o.woc);
                this.chart_labels.push(this.getWeekDateShort(o.result_date));
                this.chart_values.push(o.biomass);
            })
            this.$debug( 'chart_labels', this.chart_labels )

          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      } finally {
          this.$store.commit("loadingStatus", 0);
      }
    },

    // Total Pond Biomass
    getBiomass(count, weight) {
        if(weight) return count*453.5/weight;
        else return '';
    },

    // Get week Number for the date 
    getWeekNo(v_date_str) {
      const days = moment(v_date_str).diff(moment(this.pond.date), 'days') + 1
      this.$debug('getWeekNo', moment(v_date_str), moment(this.pond.date), days, Math.ceil(days / 7))
      return Math.ceil(days / 7);
    },
  }
}
</script>
<style scoped>

.minnowtech {
  background-color: #eee
}
.weekno {
  color: #07124f;
  font-weight: bold;
  margin-right: 20px;
}
.border-left {
  border-left: 1px solid #e2eaf1
}
.border-right {
  border-right: 1px solid #e2eaf1
}

.sample-container {
  /* height: calc(100vh - 160px); */
}
.scroll {
  overflow: auto;
}
.scroll .v-data-table {
  width: 100%;
  max-width: none;
  position: relative;
}
.scroll th {
  position: sticky;
  top: 0px;
  z-index: 2;
}
.woc-date {
  width: 100px;
  font-size: 12px;
  margin-left: 0px;
}
.custom-header {
  background-color: #46BFC6; 
}
</style>